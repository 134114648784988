import MaterialIconsWoff from '../assets/fonts/icons/material-icons-regular.woff'
import MaterialIconsWoff2 from '../assets/fonts/icons/material-icons-regular.woff2'

import DanzzaWoff from '../assets/fonts/danzza/Danzza-Regular.woff'
import DanzzaWoff2 from '../assets/fonts/danzza/Danzza-Regular.woff2'

import DanzzaMediumWoff from '../assets/fonts/danzza/Danzza-Medium.woff'
import DanzzaMediumWoff2 from '../assets/fonts/danzza/Danzza-Medium.woff2'

import DanzzaBoldWoff from '../assets/fonts/danzza/Danzza-Bold.woff'
import DanzzaBoldWoff2 from '../assets/fonts/danzza/Danzza-Bold.woff2'

import SerifGothicStdExtraBoldWoff from '../assets/fonts/serif/SerifGothicStd-ExtraBold.woff'
import SerifGothicStdExtraBoldWoff2 from '../assets/fonts/serif/SerifGothicStd-ExtraBold.woff2'

import SerifGothicStdHeavyWoff from '../assets/fonts/serif/SerifGothicStd-Heavy.woff'
import SerifGothicStdHeavyWoff2 from '../assets/fonts/serif/SerifGothicStd-Heavy.woff2'

export const fontFace = (fontName, woff, woff2, fontWeight = 'normal', fontStyle = 'normal') => `
	@font-face {
		font-family: '${ fontName }';
		src:  url('${ woff }') format('woff'),
					url('${ woff2 }') format('woff2');
		font-weight: ${ fontWeight };
		font-style: ${ fontStyle };
		font-display: swap;
	}
`
export const MaterialIcons = 'Material Icons'
export const MaterialIconsFont = fontFace(MaterialIcons, MaterialIconsWoff, MaterialIconsWoff2)

export const Danzza = 'Danzza'
export const DanzzaFont = fontFace(Danzza, DanzzaWoff, DanzzaWoff2)
export const DanzzaMediumFont = fontFace(Danzza, DanzzaMediumWoff, DanzzaMediumWoff2, '500')
export const DanzzaBoldFont = fontFace(Danzza, DanzzaBoldWoff, DanzzaBoldWoff2, '700')

export const SerifGothicStd = 'SerifGothicStd'
export const SerifGothicStdExtraBoldFont = fontFace(SerifGothicStd, SerifGothicStdExtraBoldWoff, SerifGothicStdExtraBoldWoff2, '800')
export const SerifGothicStdHeavyFont = fontFace(SerifGothicStd, SerifGothicStdHeavyWoff, SerifGothicStdHeavyWoff2, '900')
