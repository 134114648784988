import * as colors from './colors'
import { lighten, darken, rgba, toColorString } from 'polished'

// Themes (ThemeSelector Component)
export const themes = {
  default: {
    color: colors.textColor,
    background: colors.bgColor,
    hoverColor: colors.mainColor,
    hoverBackground: darken(0.07, colors.mainColor)
  },
  white: {
    color: colors.textColor,
    background: colors.white,
    hoverColor: colors.mainColor,
    hoverBackground: darken(0.07, colors.mainColor)
  },
  black: {
    color: colors.offwhite,
    background: colors.black,
    hoverColor: colors.mainColor,
    hoverBackground: darken(0.07, colors.mainColor)
  },
  green: {
    color: colors.offwhite,
    background: colors.green,
    hoverColor: colors.textColor,
    hoverBackground: darken(0.07, colors.green)
  },
  offwhite: {
    color: colors.black,
    background: colors.offwhite,
    hoverColor: colors.mainColor,
    hoverBackground: darken(0.07, colors.mainColor)
  },
  pink: {
    color: colors.darkPink,
    background: colors.pink,
    hoverColor: colors.mainColor,
    hoverBackground: darken(0.07, colors.mainColor)
  },
  yellow: {
    color: colors.darkYellow,
    background: colors.yellow,
    hoverColor: colors.mainColor,
    hoverBackground: darken(0.07, colors.mainColor)
  },
  red: {
    color: colors.offwhite,
    background: colors.red,
    hoverColor: darken(0.07, colors.red),
    hoverBackground: darken(0.07, colors.mainColor)
  },

  lightGrey: {
    color: colors.textColor,
    background: colors.lightGrey,
    hoverColor: colors.textColor,
    hoverBackground: darken(0.07, colors.lightGrey)
  },
  mainColor: {
    color: colors.bgColor,
    background: colors.mainColor,
    hoverColor: colors.bgColor,
    hoverBackground: lighten(0.07, colors.mainColor)
  },
  textColor: {
    color: colors.bgColor,
    background: colors.textColor,
    hoverColor: colors.bgColor,
    hoverBackground: lighten(0.2, colors.textColor)
  }
}

// Button Themes
export const buttonThemes = {
  default: {
    color: colors.black,
    borderColor: colors.black,
    background: colors.transparent,
    hoverColor: colors.white,
    borderHoverColor: colors.black,
    hoverBackground: colors.black
  },
  white: {
    color: colors.white,
    borderColor: colors.white,
    background: colors.transparent,
    hoverColor: colors.black,
    borderHoverColor: colors.white,
    hoverBackground: colors.white
  },
  whiteSolid: {
    color: colors.black,
    borderColor: colors.white,
    background: colors.white,
    hoverColor: colors.white,
    borderHoverColor: colors.black,
    hoverBackground: colors.black
  },
  black: {
    color: colors.black,
    borderColor: colors.black,
    background: colors.transparent,
    hoverColor: colors.white,
    borderHoverColor: colors.black,
    hoverBackground: colors.black
  },
  blackSolid: {
    color: colors.white,
    borderColor: colors.black,
    background: colors.black,
    hoverColor: colors.black,
    borderHoverColor: colors.white,
    hoverBackground: colors.white
  },
  blackSolid2: {
    color: colors.white,
    borderColor: colors.black,
    background: colors.black,
    hoverColor: colors.black,
    borderHoverColor: colors.black,
    hoverBackground: colors.transparent
  },
  blackSolidAndOffwhite: {
    color: colors.offwhite,
    borderColor: colors.black,
    background: colors.black,
    hoverColor: colors.black,
    borderHoverColor: colors.black,
    hoverBackground: colors.transparent
  },
  green: {
    color: colors.green,
    borderColor: colors.green,
    background: colors.transparent,
    hoverColor: colors.offwhite,
    borderHoverColor: colors.green,
    hoverBackground: colors.green
  },
  greenSolid: {
    color: colors.offwhite,
    borderColor: colors.green,
    background: colors.green,
    hoverColor: colors.green,
    borderHoverColor: colors.offwhite,
    hoverBackground: colors.offwhite
  },
  offwhite: {
    color: colors.offwhite,
    borderColor: colors.offwhite,
    background: colors.transparent,
    hoverColor: colors.black,
    borderHoverColor: colors.offwhite,
    hoverBackground: colors.offwhite
  },
  offwhiteSolid: {
    color: colors.black,
    borderColor: colors.offwhite,
    background: colors.offwhite,
    hoverColor: colors.offwhite,
    borderHoverColor: colors.black,
    hoverBackground: colors.black
  },
  pink: {
    color: colors.darkPink,
    borderColor: colors.darkPink,
    background: colors.transparent,
    hoverColor: colors.pink,
    borderHoverColor: colors.darkPink,
    hoverBackground: colors.darkPink
  },
  pinkSolid: {
    color: colors.darkPink,
    borderColor: colors.pink,
    background: colors.pink,
    hoverColor: colors.pink,
    borderHoverColor: colors.darkPink,
    hoverBackground: colors.darkPink
  },
  yellow: {
    color: colors.yellow,
    borderColor: colors.yellow,
    background: colors.transparent,
    hoverColor: colors.darkYellow,
    borderHoverColor: colors.yellow,
    hoverBackground: colors.yellow,
  },
  yellowSolid: {
    color: colors.darkYellow,
    borderColor: colors.yellow,
    background: colors.yellow,
    hoverColor: colors.yellow,
    borderHoverColor: colors.darkYellow,
    hoverBackground: colors.darkYellow,
  },
  red: {
    color: colors.red,
    borderColor: colors.red,
    background: colors.transparent,
    hoverColor: colors.yellow,
    borderHoverColor: colors.red,
    hoverBackground: colors.red
  },
  redSolid: {
    color: colors.yellow,
    borderColor: colors.red,
    background: colors.red,
    hoverColor: colors.red,
    borderHoverColor: colors.yellow,
    hoverBackground: colors.yellow
  },
  lightGrey: {
    color: darken(0.07, colors.lightGrey),
    borderColor: darken(0.07, colors.lightGrey),
    background: colors.transparent,
    hoverColor: colors.textColor,
    borderHoverColor: darken(0.07, colors.lightGrey),
    hoverBackground: darken(0.07, colors.lightGrey)
  },
  mainColor: {
    color: colors.black,
    borderColor: colors.black,
    background: colors.transparent,
    hoverColor: colors.white,
    borderHoverColor: colors.black,
    hoverBackground: colors.black
  },
  textColor: {
    color: colors.black,
    borderColor: colors.black,
    background: colors.transparent,
    hoverColor: colors.white,
    borderHoverColor: colors.black,
    hoverBackground: colors.black
  },
  bgColor: {
    color: colors.black,
    borderColor: colors.black,
    background: colors.transparent,
    hoverColor: colors.white,
    borderHoverColor: colors.black,
    hoverBackground: colors.black
  },
  transparent: {
    color: colors.black,
    borderColor: colors.transparent,
    background: colors.transparent,
    hoverColor: colors.green,
    borderHoverColor: colors.transparent,
    hoverBackground: colors.transparent
  }
}

// Input Themes
export const inputThemes = {
  default: {
    color: colors.black,
    background: colors.transparent,
    hoverColor: colors.white,
    borderColor: colors.black,
    borderHoverColor: colors.black,
    hoverBackground: colors.transparent,
    focusBackground: colors.transparent,
    accentColor: colors.offwhite,
    focusBorderColor: colors.black,

  },
  white: {
    color: colors.bgColor,
    background: colors.transparent,
    accentColor: colors.bgColor,
    hoverColor: colors.bgColor,
    borderColor: colors.lightTextColor,
    borderHoverColor: colors.textColor,
    focusBorderColor: colors.mainColor,
    hoverBackground: colors.transparent,
    focusBackground: colors.transparent,
  },
  black: {
    color: colors.black,
    background: colors.transparent,
    accentColor: colors.black,
    hoverColor: colors.black,
    borderColor: colors.black,
    borderHoverColor: colors.black,
    focusBorderColor: colors.black,
    hoverBackground: colors.transparent,
    focusBackground: colors.transparent,
  },
  green: {
    color: colors.green,
    background: colors.transparent,
    accentColor: colors.offwhite,
    hoverColor: colors.offwhite,
    borderColor: colors.green,
    borderHoverColor: colors.offwhite,
    focusBorderColor: colors.offwhite,
    hoverBackground: colors.transparent,
    focusBackground: colors.transparent,
  },
  offwhite: {
    color: colors.offwhite,
    background: colors.transparent,
    accentColor: colors.offwhite,
    hoverColor: colors.black,
    borderColor: colors.offwhite,
    borderHoverColor: colors.black,
    focusBorderColor: colors.offwhite,
    hoverBackground: colors.transparent,
    focusBackground: colors.transparent,
  },
  pink: {
    color: colors.darkPink,
    background: colors.transparent,
    accentColor: colors.pink,
    hoverColor: colors.pink,
    borderColor: colors.darkPink,
    borderHoverColor: colors.darkPink,
    focusBorderColor: colors.pink,
    hoverBackground: colors.transparent,
    focusBackground: colors.transparent,
  },
  yellow: {
    color: colors.yellow,
    background: colors.transparent,
    accentColor: colors.yellow,
    hoverColor: colors.darkYellow,
    borderColor: colors.yellow,
    borderHoverColor: colors.yellow,
    focusBorderColor: colors.darkYellow,
    hoverBackground: colors.transparent,
    focusBackground: colors.transparent,
  },
  red: {
    color: colors.red,
    background: colors.transparent,
    accentColor: colors.red,
    hoverColor: colors.red,
    borderColor: colors.red,
    borderHoverColor: colors.yellow,
    focusBorderColor: colors.yellow,
    hoverBackground: colors.transparent,
    focusBackground: colors.transparent,
  }
}

export const footerThemes = {
  red: {
    background: colors.red,
    color: colors.offwhite,
  },
  yellow: {
    background: colors.yellow,
    color: colors.offwhite
  },
  pink: {
    background: colors.pink,
    color: colors.offwhite
  },
  default: {
    background: colors.green,
    color: colors.offwhite
  },
  green: {
    background: colors.green,
    color: colors.offwhite
  },
  black: {
    background: colors.black,
    color: colors.offwhite
  },
  offwhite: {
    background: colors.offwhite,
    color: colors.textColor
  },
  white: {
    background: colors.white,
    color: colors.textColor
  }
}

export const lookbookPreviewThemes = {
  red: {
    color: colors.yellow,
    backgroundColor: colors.red
  },
  pink: {
    color: colors.darkPink,
    backgroundColor: colors.pink
  },
  offwhite: {
    color: colors.darkPink,
    backgroundColor: colors.offwhite
  },
  white: {
    color: colors.black,
    backgroundColor: colors.white
  },
  yellow: {
    color: colors.darkPink,
    backgroundColor: colors.yellow
  },
  green: {
    color: colors.offwhite,
    backgroundColor: colors.green
  }
}

export default themes
