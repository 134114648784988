/* eslint-disable no-console */
import React from 'react'
import ShopifyClient from 'shopify-buy'
import { get, set, del } from 'src/utils/localStorage'
import { resolve } from 'core-js/fn/promise'
import { fbTrack } from 'src/utils/tracking'

const initialState = {
	cart: {},
	checkoutId: null,
	collections: [],
	products: [],
	dictionary: {},
	resizeShopImage: () => { },
	addLineItemToCart: () => { },
	removeLineItemFromCart: () => { },
	updateLineItemInCart: () => { },
	addDiscount: () => { },
	removeDiscount: () => { },
	fetchProductByHandle: () => { },
}

const ShopifyContext = React.createContext(initialState)

export const PriceV2Fragment = (price) => {
  price.add('amount')
}

export const ImageFrament = (image) => {
  image.add('id')
  image.add('url')
  image.add('altText')
}

export const ProductVariantFragment = (variant) => {
  variant.add('id')
  variant.add('title')
  variant.add('sku')
	variant.add('price', PriceV2Fragment)
  variant.add('availableForSale')
  // variant.add('image', ImageFrament)
	variant.add('selectedOptions', selectedOption => {
		selectedOption.add('value')
	})
}

export const ProductFragment = (product) => {
  product.add('id')
  product.add('title')
  product.add('handle')
  product.add('productType')
  product.add('description')
  product.add('descriptionHtml')
  product.add('vendor')
  product.add('availableForSale')
  product.addConnection('images', {args: {first: 50}}, ImageFrament)
  product.addConnection('variants', {args: {first: 10}}, ProductVariantFragment)
	product.add('options', option => {
		option.add('name')
		option.add('values')
	})
  // product.add('metafields', {
  //   args: {identifiers: [
  //     { namespace: 'specifications', key: 'width' },
  //     { namespace: 'specifications', key: 'care' },
  //     { namespace: 'specifications', key: 'content' },
  //     { namespace: 'specifications', key: 'performance' }
  //   ]}
  // }, MetafieldFragment)
}

export const CollectionFragment = (collection) => {
	collection.add('id')
	collection.add('handle')
	collection.add('description')
	collection.add('descriptionHtml')
	collection.add('title')
  collection.add('image', ImageFrament)
  collection.addConnection('products', {args: {first: 50}}, ProductFragment)
}

export const imageForSize = (image, {maxWidth, maxHeight}) => {
	if (image?.url || image?.src) {
		const imgUrl = image?.url || image?.src
		const splitUrl = imgUrl.split('?')[0];
		console.log(splitUrl)
		return (splitUrl + `?width=` + maxWidth)
	} else {
		return false
	}
}

class ShopifyState extends React.Component {
	constructor (props) {
		super(props)
		this.state = initialState
		this.shopifyClient = null
	}

	componentDidMount () {
		this.shopifyClient = ShopifyClient
			.buildClient({
				domain: 'yonyclothing.myshopify.com',
				storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
			})
		this.fetchAllCollectionsWithProducts()
		this.getCheckout()
		this.buildDictionary()

		console.log(this.shopifyClient)

		this.setState({ resizeShopImage: this.shopifyClient.image.helpers.imageForSize })
		this.setState({ resizeShopImage: imageForSize })
	}

	componentDidUpdate () {
		const { checkoutId } = this.state
		if (!checkoutId) this.getCheckout()
	}

	setValue = obj => new Promise((resolve, reject) => {
		if (typeof obj !== 'object') resolve()
		else this.setState(obj, resolve)
	})

	buildDictionary = () => {
		// Build a custom products query using the unoptimized version of the SDK
		const productsQuery = this.shopifyClient.graphQLClient.query(root => {
			root.addConnection('products', { args: { first: 150 } }, product => {
				product.add('id')
				product.add('title')
				product.add('handle')
				product.addConnection('variants', { args: { first: 1 } }, variant => {
					variant.add('id')
					variant.add('availableForSale')
					variant.add('selectedOptions', selectedOption => {
						selectedOption.add('value')
					})
				})
			})
		})

		this.shopifyClient.graphQLClient.send(productsQuery).then(({ model, data }) => {
			const products = data.products.edges.map(x => x.node)
			if (!products) return console.error(new Error('error fetching products: no products value'))
			const dictionary = products.reduce((acc, product) => {
				const { title } = product
				if (!acc[title]) acc[title] = []
				acc[title] = [...acc[title], product]
				return acc
			}, {})
			this.setValue({ dictionary })
			// Object.keys(dictionary).forEach(key => {
			// find shortest handle (i.e. cpo-shirt instead of cpo-shirt-1)
			// const shortestHandle = dictionary[key].sharedHandle = dictionary[key].map(x => x.handle).sort()[0]
			// })
		})
	}

	fetchAllCollectionsWithProducts = () => {
		const collectionsQuery = this.shopifyClient.graphQLClient.query(root => {
			root.addConnection('collections', {args: {first: 40}}, CollectionFragment)
		})

		this.shopifyClient.graphQLClient.send(collectionsQuery).then((obj) => {
			const {data, model} = obj
			const dataWithNextPageQueryPath = model && model.collections
			this.fetchNextPageCollections({
				dataWithNextPageQueryPath,
				newCollectionsData: data.collections,
				allCollections: data.collections.edges.map(x => x.node),
				allModelCollections: model && model.collections
			})
				.then(({ allCollections, allModelCollections }) => {
					const collectionPromisesArray = allCollections.map((collection, index) =>
						new Promise((resolve, reject) => {
							const dataWithNextPageQueryPath = allModelCollections.find(x => x.id === collection.id).products
							this.fetchNextPageCollectionsProducts({
								dataWithNextPageQueryPath,
								newProductsData: collection.products,
								allProducts: collection.products.edges.map(x => x.node),
							}).then(allProducts => {
								allCollections[index].products = allProducts
								resolve()
							})
						})
					)
					Promise.all(collectionPromisesArray)
						.then(() => {
							if (allCollections) this.setValue({ collections: allCollections })
						})
				})
		})
	}

	fetchNextPageCollections = ({ dataWithNextPageQueryPath, newCollectionsData, allCollections, allModelCollections }) => new Promise((resolve, reject) => {
		// recursive search for next page. returns array of collections
		if (newCollectionsData && newCollectionsData.pageInfo.hasNextPage) {
			this.shopifyClient.fetchNextPage(dataWithNextPageQueryPath)
				.then(({ data, model }) => {
					const object = {
						dataWithNextPageQueryPath: model,
						newCollectionsData: data && data.collections,
						allCollections: [...allCollections, ...(data.collections.edges.map(x => x && x.node))],
						allModelCollections: [...allModelCollections, ...((model && model.collections) || model)]
					}
					resolve(this.fetchNextPageCollections(object))
				})
		} else {
			return (resolve({ allCollections, allModelCollections }))
		}
	})

	fetchNextPageCollectionsProducts = ({ dataWithNextPageQueryPath, newProductsData, allProducts }) => new Promise((resolve, reject) => {
		// recursive search for next page. returns array of products
		if (newProductsData && newProductsData.pageInfo.hasNextPage) {
			this.shopifyClient.fetchNextPage(dataWithNextPageQueryPath)
				.then(({ data, model }) => {
					const object = {
						dataWithNextPageQueryPath: model,
						newProductsData: data.node && data.node.products,
						allProducts: [...allProducts, ...(data.node.products.edges.map(x => x && x.node))]
					}
					resolve(this.fetchNextPageCollectionsProducts(object))
				})
		} else {
			return resolve(allProducts)
		}
	})

	fetchCollectionById = ({ collectionId }) => {
		this.shopifyClient.collection
			.fetchAllWithProducts(collectionId, { productsFirst: 100 })
			.then(collection => {
				return collection
			})
	}

	fetchProductById = ({ productId }) => {
		// returns promise
		let client = this.shopifyClient
		if (!client) {
			client = ShopifyClient
				.buildClient({
					domain: 'yonyclothing.myshopify.com',
					storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
				})
		}
		return client.product.fetch(productId)
	}

	fetchProductByHandle = ({ productHandle }) => {
		// returns promise
		let client = this.shopifyClient
		if (!client) {
			client = ShopifyClient
				.buildClient({
					domain: 'yonyclothing.myshopify.com',
					storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
				})
		}
		return client.product.fetchByHandle(productHandle)
	}

	getCheckout = () => {
		const checkoutId = get('checkoutId')
		if (checkoutId) {
			this.fetchCheckout({ checkoutId })
		} else {
			this.createCheckout()
		}
	}

	createCheckout = () => {
		this.shopifyClient.checkout
			.create()
			.then(cart => {
				set('checkoutId', cart.id)
				this.setValue({ checkoutId: cart.id, cart })
			})
			.catch(e => console.error(new Error(e || 'error fetching products')))
	}

	fetchCheckout = ({ checkoutId }) => {
		this.shopifyClient.checkout
			.fetch(checkoutId)
			.then(cart => {
				if (cart.completedAt) {
					// already completed checkout
					del('checkoutId')
					this.createCheckout()
				} else {
					this.setValue({ checkoutId, cart })
				}
			}).catch(error => {
				del('checkoutId')
				this.createCheckout()
			})
	}

	addLineItemToCart = ({ variantId, quantity = 1 }) =>
		// returns promise
		new Promise((resolve, reject) => {
			const items = [{ variantId, quantity }]
			const { checkoutId } = this.state
			this.shopifyClient.checkout
				.addLineItems(checkoutId, items)
				.then(cart => this.setValue({ cart }))
				.then(() => {
					/* TOGGLE CART DRAWER */
					const contents = items.map(({quantity, variantId}) => {
						const contentId = variantId.split('gid://shopify/ProductVariant/')[1]
						return {
							id: contentId,
							quantity: quantity
						}
					})

					fbTrack('track', 'AddToCart', {
						currency: 'USD',
						content_type: 'product_group',
						contents: contents
					})

					resolve()
				})
				.catch(e => reject(new Error(e || 'error adding line item to cart')))
		})

	removeLineItemFromCart = ({ id }) => {
		const items = [id]
		const { checkoutId } = this.state
		this.shopifyClient.checkout
			.removeLineItems(checkoutId, items)
			.then(cart => this.setValue({ cart }))
			.catch(e => console.error(new Error(e || 'error removing line item from cart')))
	}

	updateLineItemInCart = ({ id, quantity }) => {
		const items = [{ id, quantity }]
		const { checkoutId } = this.state
		this.shopifyClient.checkout
			.updateLineItems(checkoutId, items)
			.then(cart => this.setValue({ cart }))
			.catch(e => console.error(new Error(e || 'error updating line item in cart')))
	}

	addDiscount = ({ discountCode }) => {
		const { checkoutId } = this.state
		this.shopifyClient.checkout
			.addDiscount(checkoutId, discountCode)
			.then(cart => this.setValue({ cart }))
			.catch(e => console.error(new Error(e | 'error adding discount')))
	}

	removeDiscount = () => {
		const { checkoutId } = this.state
		this.shopifyClient.checkout
			.removeDiscount(checkoutId)
			.then(cart => this.setValue({ cart }))
			.catch(e => console.error(new Error(e | 'error removing discount')))
	}

	render () {
		const { children } = this.props
		return (
			<ShopifyContext.Provider
				value={{
					...this.state,
					fetchProductById: this.fetchProductById,
					fetchProductByHandle: this.fetchProductByHandle,
					addLineItemToCart: this.addLineItemToCart,
					removeLineItemFromCart: this.removeLineItemFromCart,
					updateLineItemInCart: this.updateLineItemInCart,
					addDiscount: this.addDiscount,
					removeDiscount: this.removeDiscount,
				}}
			>
				{children}
			</ShopifyContext.Provider >
		)
	}
}

export default ShopifyState

export const withShopifyContext = Component => props => (
	<ShopifyContext.Consumer>
		{context => (
			<Component{...props}
				shopifyContext={context}
			/>
		)}
	</ShopifyContext.Consumer>
)
