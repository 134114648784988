import { lighten, darken, rgba } from 'polished'

export const black = '#1A1919'
export const white = '#ffffff'

// Site Specific Colors
export const offwhite = '#FAF4DE'
export const pink = '#B58E80'
export const darkPink = '#783E32'
export const yellow = '#E0A300'
export const darkYellow = '#813A2E'
export const green = '#708166'
export const red = '#853329'

// Basic Colors
export const transparent = 'transparent'
export const currentcolor = 'currentcolor'
export const bgColor = white
export const textColor = '#1a1919'
export const mainColor = textColor
export const alert = red
export const notify = yellow
export const success = green
export const lightTextColor = rgba(green, 0.8)
export const lightGrey = rgba(green, 0.1)
export const hrColor = rgba(green, 0.25)

// Color Variations
export const mainColorDarken = darken(0.07, mainColor)
export const mainColorLighten = lighten(0.07, mainColor)
