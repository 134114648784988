import React from 'react'

const initialState = {
  isOpen: false,
  mobileMenuIsOpen: false,
  tab: null,
  toggleCart: () => { },
  toggleMobileMenu: () => { }
}

export const TABS = {
  CART: 'CART'
}

export const CartContext = React.createContext(initialState)

class CartState extends React.Component {
  constructor (props) {
    super(props)
    this.state = initialState
  }

  componentDidMount () {
    document.addEventListener('keydown', this.escFunction, false)
  }

  componentWillUnmount () {
    document.removeEventListener('keydown', this.escFunction, false)
  }

  escFunction = e => {
    if (e && e.keyCode === 27) { // escape key maps to keycode `27`
      this.toggleCart()
    }
  }

  toggleCart = newTab => {
    const { isOpen, tab } = this.state
    if (newTab && !(isOpen && tab)) this.setState({
      tab: newTab,
      isOpen: true,
      mobileMenuIsOpen: false
    })
    else this.setState({ isOpen: false })
  }

  toggleMobileMenu = () => {
    const { mobileMenuIsOpen } = this.state
    if (!mobileMenuIsOpen) this.setState({ mobileMenuIsOpen: true, isOpen: false })
    else this.setState({ mobileMenuIsOpen: false })
  }

  render () {
    const { children } = this.props
    return (
      <CartContext.Provider
        value={{
          ...this.state,
          toggleCart: this.toggleCart,
          toggleMobileMenu: this.toggleMobileMenu,
        }}
      >
        {children}
      </CartContext.Provider>
    )
  }
}

export default CartState

export const withCartContext = Component => props => (
  <CartContext.Consumer>
    {context => <Component {...props} cartContext={context} />}
  </CartContext.Consumer>
)
