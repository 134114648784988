// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-article-template-jsx": () => import("./../../../src/templates/ArticleTemplate.jsx" /* webpackChunkName: "component---src-templates-article-template-jsx" */),
  "component---src-templates-blog-page-template-jsx": () => import("./../../../src/templates/BlogPageTemplate.jsx" /* webpackChunkName: "component---src-templates-blog-page-template-jsx" */),
  "component---src-templates-collection-template-jsx": () => import("./../../../src/templates/CollectionTemplate.jsx" /* webpackChunkName: "component---src-templates-collection-template-jsx" */),
  "component---src-templates-lookbook-template-jsx": () => import("./../../../src/templates/LookbookTemplate.jsx" /* webpackChunkName: "component---src-templates-lookbook-template-jsx" */),
  "component---src-templates-page-template-jsx": () => import("./../../../src/templates/PageTemplate.jsx" /* webpackChunkName: "component---src-templates-page-template-jsx" */),
  "component---src-templates-product-template-jsx": () => import("./../../../src/templates/ProductTemplate.jsx" /* webpackChunkName: "component---src-templates-product-template-jsx" */)
}

